import { signIn, signOut } from 'aws-amplify/auth';

import { configureAmplify } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

export function logIn(email, password) {
  configureAmplify();

  return signIn({ username: email.toLowerCase(), password }).catch(error => {
    if (error.name === 'UserNotFoundException') {
      return signIn({ username: email, password });
    }

    throw error;
  });
}

export function logOut() {
  return signOut();
}

export function getVetUserSelf() {
  return fetchWrapper('/users/self', {
    isAuth: true,
  });
}

export function getVetUser(id) {
  return fetchWrapper(`/users/${id}`, {
    isAuth: true,
  });
}
