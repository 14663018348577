import classNames from 'classnames';

import { formatISODate } from '@pumpkincare/portal-shared';
import { Body2 } from '@pumpkincare/portal-ui';

import styles from './challenge.css';

import q3Challenge from './q3-challenge-header.png';

function Challenge({ challengeData = {} }) {
  const {
    start_date: startDate,
    end_date: endDate,
    current_value: currentValue,
    is_active: isActive,
  } = challengeData;

  return (
    <div className={styles.root}>
      <img src={q3Challenge} alt='' className={styles.img} />

      <h3>Number of new pet owner visits to the Pumpkin website</h3>
      {isActive ? (
        <Body2>
          Challenge Period of {formatISODate(startDate, { format: 'MMM D' })} to{' '}
          {formatISODate(endDate, { format: 'MMM D, YYYY' })}
        </Body2>
      ) : (
        <Body2>Since {formatISODate(startDate, { format: 'MMM D' })}</Body2>
      )}

      <p
        className={classNames(styles.number, {
          [styles.inactive]: !isActive,
        })}
      >
        {currentValue}
      </p>

      <div className={styles.block}>
        <p>
          <b>
            Win 1 of 4 prizes based on your team's total visit count, up to 150
            visits!
          </b>
        </p>
        <p>
          Plus, get 150 or more new visits during the challenge and Pumpkin will
          continue to supply your practice with custom brochures moving forward!
        </p>
      </div>
    </div>
  );
}

export default Challenge;
