import { A, Body1 } from '@pumpkincare/portal-ui';

import { ADDRESS } from '../../constants';
import {
  PATH_EXT_CALIFORNIA_NOTICE,
  PATH_EXT_COOKIES,
  PATH_EXT_CUSTOMER_AGREEMENT,
  PATH_EXT_INSURANCE_POLICY,
  PATH_EXT_MAINE_NOTICE,
  PATH_EXT_PRIVACY,
  PATH_EXT_TERMS,
  PATH_EXT_UNDERWRITING,
  PATH_TERMS,
} from '../../routing';

import styles from './footer.css';

export default function Footer({ isCustomer }) {
  const termsLink = isCustomer ? PATH_EXT_TERMS : PATH_TERMS;

  return (
    <>
      <div className={styles.content}>
        <A href={PATH_EXT_CALIFORNIA_NOTICE} target='_blank'>
          Notice to California Residents
        </A>

        <A href={PATH_EXT_MAINE_NOTICE} target='_blank'>
          Notice to Maine Residents
        </A>

        <Body1>
          Pumpkin Pet Insurance policies do not cover pre-existing conditions.
          Waiting periods, annual deductible, co-insurance, benefit limits and
          exclusions may apply. For full terms, visit{' '}
          <A href={PATH_EXT_INSURANCE_POLICY} target='_blank'>
            pumpkin.care/insurancepolicy
          </A>
          . Products and rates may vary and are subject to change. Discounts may vary
          and are subject to change.
        </Body1>

        <Body1>
          Insurance products are underwritten by either Independence American
          Insurance Company, a Delaware insurance company (NAIC #2658; Scottsdale,
          AZ), or United States Fire Insurance Company (NAIC #21113; Morristown, NJ);
          and are administered and produced by Pumpkin Insurance Services Inc.
          (“Pumpkin”) (NPN #19084749; domiciled in New York with offices at $
          {ADDRESS}; CA License #6001617). Please refer to your policy forms to
          determine your policy’s underwriter. Pumpkin is a licensed insurance
          agency, not an insurer, and receives compensation based on the premiums for
          the insurance policies it sells. For more details visit{' '}
          <A href={PATH_EXT_UNDERWRITING} target='_blank'>
            pumpkin.care/underwriting-information
          </A>
          .
        </Body1>

        <Body1>
          Pumpkin Preventive Essentials is not an insurance policy. It is offered as
          an optional add-on non-insurance benefit. Pumpkin is responsible for the
          product and administration. For full terms, visit{' '}
          <A href={PATH_EXT_CUSTOMER_AGREEMENT} target='_blank'>
            pumpkin.care/customeragreement
          </A>
          . Pumpkin Preventive Essentials is not available in all states.
        </Body1>

        <Body1>
          © {new Date().getFullYear()} Pumpkin Insurance Services Inc. All rights
          reserved.
        </Body1>
      </div>

      <div className={styles.links}>
        <A href={termsLink} target='_blank' className={styles.link}>
          Terms of Use
        </A>
        <span className={styles.separator}>|</span>
        <A href={PATH_EXT_PRIVACY} target='_blank' className={styles.link}>
          Privacy Policy
        </A>
        <span className={styles.separator}>|</span>
        <A href={PATH_EXT_COOKIES} target='_blank' className={styles.link}>
          Cookies Policy
        </A>
      </div>
    </>
  );
}
