import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Store from 'store';

import {
  useDashboardFacts,
  useDashboardMemes,
  useDashboardQuizzes,
  useDashboardVets,
} from '@pumpkincare/portal-dashboard';

import {
  DASHBOARD_INDEXES,
  FACT_INDEX,
  MEME_INDEX,
  QUIZ_INDEX,
} from '../../constants/local-storage';
import Aside from '../aside';
import Challenge from '../challenge';
import DashboardLegal from '../dashboard-legal';
import Fact from '../fact';
import LearningLabAside from '../learning-lab-aside';
import Meme from '../meme';
import Quiz from '../quiz';
import QuotesPolicyTracker from '../quotes-policy-tracker';
import VetDiscount from '../vet-discount';

import boneAppetit from './q3-challenge.png';

const HOUR = 1000 * 60 * 60;

// [challenge, [quiz, fact], quotes&policies, vet discount, legal]
const timers = [10000, [25000, 10000], 10000, 10000, 10000];
// if user passes in ?view=KEY, user can stay on one screen for testing
const testViews = {
  challenge: { viewIndex: 0, loopIndex: 0 },
  quiz: { viewIndex: 1, loopIndex: 0 },
  fact: { viewIndex: 1, loopIndex: 1 },
  'quotes-all': { viewIndex: 2, loopIndex: 0 },
  'quotes-month': { viewIndex: 2, loopIndex: 1 },
  discount: { viewIndex: 3, loopIndex: 0 },
  legal: { viewIndex: 4, loopIndex: 0 },
};

function Dashboard() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const paramsVetId = params.get('vet_id');
  const date = useRef(new Date());
  const timestamp = date.current.getTime();
  const indexes = Store.get(DASHBOARD_INDEXES);

  /*
     these values are for testing only
     pass in a valid view to test a single screen without rotation
     pass in meme/quiz/fact to get different responses from cloudflare
   */
  const paramsView = params.get('view');
  const paramsMemeIndex = parseInt(params.get('meme'), 10);
  const paramsQuizIndex = parseInt(params.get('quiz'), 10);
  const paramsFactIndex = parseInt(params.get('fact'), 10);

  // by having a state object rather than individual states, state update will trigger one re-render
  const [{ memeIndex, quizIndex, factIndex }, setIndex] = useState({
    memeIndex: paramsMemeIndex || indexes?.[MEME_INDEX] || 0,
    quizIndex: paramsQuizIndex || indexes?.[QUIZ_INDEX] || 0,
    factIndex: paramsFactIndex || indexes?.[FACT_INDEX] || 0,
  });
  const [{ viewIndex, loopIndex }, setViewIndex] = useState(
    testViews[paramsView] || {
      viewIndex: 0,
      loopIndex: 0,
    }
  );
  const [indexIteratedTimestamp, setIndexIteratedTimestamp] = useState(timestamp);

  const { data: vetData, isPlaceholderData: isVetPlaceholderData } =
    useDashboardVets(paramsVetId);
  const {
    data: { total_resources: memesLength, ...memeData },
    isPlaceholderData: isMemePlaceholderData,
  } = useDashboardMemes(memeIndex);
  const {
    data: { total_resources: quizzesLength, ...quizData },
    isPlaceholderData: isQuizPlaceholderData,
  } = useDashboardQuizzes(quizIndex);
  const {
    data: { total_resources: factsLength, ...factData },
    isPlaceholderData: isFactPlaceholderData,
  } = useDashboardFacts(factIndex);

  useEffect(() => {
    const interval = setInterval(() => {
      const intDate = new Date();
      const intHour = intDate.getHours();
      const intTimestamp = intDate.getTime();

      /*
       if it's 6am or there was an error and it's been more than a day since the content updated
       set new indices for static content, so that RQ triggers new fetches
       */
      if (intHour === 6 || intTimestamp - indexIteratedTimestamp > HOUR * 24.5) {
        setIndexIteratedTimestamp(intTimestamp);
        setIndex(val => ({
          memeIndex: val.memeIndex === memesLength - 1 ? 0 : val.memeIndex + 1,
          quizIndex: val.quizIndex === quizzesLength - 1 ? 0 : val.quizIndex + 1,
          factIndex: val.factIndex === factsLength - 1 ? 0 : val.factIndex + 1,
        }));
      }
    }, HOUR);

    return () => clearInterval(interval);
  }, [indexIteratedTimestamp, memesLength, quizzesLength, factsLength]);

  useEffect(() => {
    /*
     this was tested to make sure it only triggers one lifecycle
     three separate useState would trigger this event thrice
     don't update local storage during tests
     */
    if (!paramsMemeIndex && !paramsQuizIndex && !paramsFactIndex) {
      Store.set(DASHBOARD_INDEXES, {
        [MEME_INDEX]: memeIndex,
        [QUIZ_INDEX]: quizIndex,
        [FACT_INDEX]: factIndex,
      });
    }
  }, [
    memeIndex,
    quizIndex,
    factIndex,
    paramsMemeIndex,
    paramsQuizIndex,
    paramsFactIndex,
  ]);

  const isLoaded = [
    isVetPlaceholderData,
    isMemePlaceholderData,
    isQuizPlaceholderData,
    isFactPlaceholderData,
  ].every(isPlaceholder => !isPlaceholder);

  useEffect(() => {
    /*
      once data is loaded, start the slide transitions
      do not do slide transitions if user is trying to test a view
      on timeout, increment viewIndex to see the next view
      one loop is a full set of views seen/timers expended
      if at the end of the loop, reset viewIndex and iterate on loopIndex
      after 2 loops, reset loopIndex
     */
    if (isLoaded && !testViews[paramsView]) {
      setTimeout(() => {
        setViewIndex(val => ({
          viewIndex: val.viewIndex === timers.length - 1 ? 0 : val.viewIndex + 1,
          loopIndex:
            val.viewIndex !== timers.length - 1
              ? val.loopIndex
              : val.loopIndex
              ? 0
              : 1,
        }));
      }, timers[viewIndex]?.[loopIndex] || timers[viewIndex]);
    }
  }, [isLoaded, viewIndex, loopIndex, paramsView]);

  const views = isLoaded
    ? [
        {
          // on launch only running one challenge at at time, but array structure to support multiple
          aside: <Challenge challengeData={vetData.challenges[0]} />,
          src: boneAppetit,
        },
        {
          main:
            loopIndex === 0 ? <Quiz quiz={quizData} /> : <Fact fact={factData} />,
          aside: <LearningLabAside clinicCode={vetData.learning_lab_code} />,
        },
        {
          aside: (
            <QuotesPolicyTracker
              currentMonth={vetData.current_month}
              quoteData={vetData.quotes}
              canBeCurrentMonth={loopIndex === 1}
            />
          ),
        },
        {
          aside: <VetDiscount />,
        },
        {
          aside: <DashboardLegal />,
          isLegal: true,
        },
      ]
    : [];

  const view = views[viewIndex];

  return (
    <div
      style={{
        height: '800px',
        width: '1280px',
        display: 'flex',
        margin: '0 auto auto',
      }}
    >
      {isLoaded ? (
        <>
          {view.main || <Meme src={view.src || memeData.url} />}

          <Aside
            viewIdx={viewIndex}
            numBubbles={views.length}
            isLegal={view.isLegal}
          >
            {view.aside}
          </Aside>
        </>
      ) : null}
    </div>
  );
}

export default Dashboard;
