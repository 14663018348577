import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { useDebounce } from 'use-debounce';

import {
  analyticsIdentify,
  analyticsTrack,
  getAnonymousId,
} from '@pumpkincare/analytics';
import { useMutateCreateIdentity } from '@pumpkincare/portal-identity';
import {
  useMutatePostQuote,
  useMutatePostQuotePet,
  useQuoteEmail,
} from '@pumpkincare/portal-quotes';
import {
  filterUTMParams,
  useTargetState,
  validateEmail,
} from '@pumpkincare/portal-shared';
import {
  Body2,
  ButtonPrimary,
  Radio,
  RadioGroup,
  TextField,
} from '@pumpkincare/portal-ui';
import { useVet } from '@pumpkincare/portal-vets';

import { PATH_EXT_PRIVACY, PATH_EXT_TERMS, PATH_PAWSIGHTS_FORM } from '../../../app';
import { useMutateTrackPawsightsEmail } from '../../utils/pawsights-mutations';
import PawsightsContact from '../pawsights-contact';
import PawsightsFooter from '../pawsights-footer';
import PawsightsNavBar from '../pawsights-nav-bar';
import Stepper from '../stepper';
import VetOverlay from './vet-overlay';

import styles from './pawsights-landing.css';

import bandaid from '../../../../../../../public/assets/paw-portal/icons/bandaid.svg';
import helloStep from '../../../../../../../public/assets/paw-portal/icons/hello-circle.svg';
import pawStep from '../../../../../../../public/assets/paw-portal/icons/paw-circle-inactive.svg';
import reportStep from '../../../../../../../public/assets/paw-portal/icons/report-circle-inactive.svg';
import catFace from '../../../../../../../public/assets/paw-portal/illustrations/cat/face.svg';
import dogFace from '../../../../../../../public/assets/paw-portal/illustrations/dog/face.svg';
import shield from '../../../../../../../public/assets/paw-portal/illustrations/misc/shield.svg';
import report from '../../../../../../../public/assets/paw-portal/illustrations/multipet/reports.png';

function PawsightsLanding() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const paramsVetId = new URLSearchParams(search).get('vet_id');
  const storageVetId = sessionStorage.getItem('@pumpkincare/vetId');

  const [species, setSpecies] = useState();
  const [name, setName] = useTargetState('');
  const [email, setEmail, isEmailValid] = useTargetState('', validateEmail);
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [modalVet, setModalVet] = useState({});

  const vetId = modalVet.id || paramsVetId || storageVetId;
  const [isModalOpen, setModalOpen] = useState(!vetId);

  const [debouncedEmail] = useDebounce(email, 500);
  const { data: emailData } = useQuoteEmail(debouncedEmail);
  const doesEmailExist = emailData.exists;

  // if useVet fails, placeholder data is undefined and we want it to fail silently here
  const { data: vetData = {}, isFetching: isVetFetching } = useVet(vetId, {
    onSuccess: ({ id, vet_name }) => {
      analyticsIdentify({ VETCLINICID: id, VETCLINICNAME: vet_name });
      sessionStorage.setItem('@pumpkincare/vetId', id);
    },
    onError: () => setModalOpen(true),
  });
  const { isLoading: isIdentityLoading, mutateAsync: createIdentity } =
    useMutateCreateIdentity();
  const { isLoading: isQuoteLoading, mutateAsync: postQuote } = useMutatePostQuote();
  const { isLoading: isQuotePetLoading, mutateAsync: postQuotePet } =
    useMutatePostQuotePet();
  const { mutate: postTrackEmail } = useMutateTrackPawsightsEmail();
  const vetName = vetData.vet_display_name || vetData.vet_name;

  useEffect(() => {
    // covers the use cases: does not track unless the user lands with a valid, fetched vet
    // or closes the vet selection modal with or without a vet
    if (!isModalOpen && !isVetFetching) {
      analyticsTrack({
        category: 'Pawsights',
        event: 'Step 1 impression',
      });
    }
  }, [isVetFetching, isModalOpen]);

  const isLoading = [isIdentityLoading, isQuoteLoading, isQuotePetLoading].some(
    loading => !!loading
  );

  function formatEmailError() {
    if (doesEmailExist)
      return 'We see you’re already using this email for your Pumpkin account – please use a different email to receive your report.';
    if (!isEmailValid) return 'Please enter a valid email';

    return '';
  }

  function handleVetOverlaySuccess(vet) {
    setModalVet(vet);
    setModalOpen(false);
  }

  function handleVetSkip() {
    analyticsIdentify({ VETCLINICNAME: 'No Vet' });
    setModalOpen(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!name || !species || !!formatEmailError()) {
      setHasTriedToSubmit(true);
      return;
    }

    const trackingId = getAnonymousId();
    const payload = trackingId ? { trackingId } : {};

    sessionStorage.setItem('@pumpkincare/email', email);
    postTrackEmail({ email });

    createIdentity(payload)
      .then(({ id: identityId }) => {
        sessionStorage.setItem('@pumpkincare/identityId', identityId);
        return postQuote({ identity_id: identityId, affiliate_code: 'pawsights' });
      })
      .then(({ id: quoteId }) => {
        sessionStorage.setItem('@pumpkincare/quoteId', quoteId);
        return postQuotePet({ quoteId, pet_name: name, species });
      })
      .then(({ id: quotePetId }) => {
        sessionStorage.setItem('@pumpkincare/quotePetId', quotePetId);
        // quote_id in search used for data funnel, not in code
        dispatch(
          push({
            pathname: PATH_PAWSIGHTS_FORM,
            search: `quote_id=${sessionStorage.getItem(
              '@pumpkincare/quoteId'
            )}&${filterUTMParams(search)}`,
          })
        );
      });
  }

  return (
    <>
      <PawsightsNavBar vetName={vetName} />

      <div className={styles.blue}>
        <div className={styles.header}>
          <div className={styles.text}>
            <h1>
              Your pet's breed is <span>key to their health.</span>
            </h1>
            <Body2 className={styles.subtitle}>
              This personalized breed report will help you:
            </Body2>

            <ul>
              <li>
                <div className={styles.iconContainer}>
                  <img src={bandaid} alt='' />
                </div>
                Recognize common breed health conditions and get expert care tips
              </li>

              <li>
                <div className={styles.iconContainer}>
                  <img src={shield} alt='' />
                </div>
                Learn how pet insurance can help you afford the best vet care with
                advice from Pumpkin Pet Insurance experts
              </li>
            </ul>
          </div>

          <img src={report} alt='' />
        </div>
      </div>

      <div className={styles.content}>
        <Stepper
          classes={{ root: styles.stepperRoot, img: styles.stepperImg }}
          activeIndex={0}
          steps={[
            { icon: helloStep, text: 'Introduction' },
            { icon: pawStep, text: 'Pet Details' },
            {
              icon: reportStep,
              text: (
                <>
                  Get <span className={styles.desktop}>Your </span>Report
                </>
              ),
            },
          ]}
        />

        <h3>Request your pet's report now!</h3>

        <form onSubmit={handleSubmit}>
          <p className={styles.question}>
            Is your pet a dog or a cat?<sup>*</sup>
          </p>

          <RadioGroup
            row
            onChange={setSpecies}
            value={species}
            classes={{ root: styles.radioGroup }}
            error={{
              errorMessage:
                hasTriedToSubmit && !species ? 'This field is required' : '',
            }}
          >
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label={
                <>
                  <img src={dogFace} alt='' />
                  <p>Dog (Arf)</p>
                </>
              }
              value='dog'
              variant='portal'
              align='center'
            />
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label={
                <>
                  <img src={catFace} alt='' />
                  <p>Cat (Meow)</p>
                </>
              }
              value='cat'
              variant='portal'
              align='center'
            />
          </RadioGroup>

          <p className={styles.question}>
            What's your pet's name?<sup>*</sup>
          </p>

          <TextField
            id='name'
            value={name}
            onChange={setName}
            placeholder="Enter your pet's name"
            isLabelHidden
            classes={{ container: styles.textField }}
            variant='portal'
            error={{
              errorMessage:
                hasTriedToSubmit && !name ? 'This field is required' : '',
            }}
          />

          <p className={styles.question}>
            Where should we send your report?<sup>*</sup>
          </p>

          <TextField
            id='email'
            placeholder='Enter your email address'
            isLabelHidden
            value={email}
            onChange={setEmail}
            variant='portal'
            classes={{
              container: styles.textField,
            }}
            legalMessage={
              <p className={styles.legalPrivacy}>
                We are committed to protecting your privacy. By submitting your
                email, you are agreeing to our{' '}
                <a href={PATH_EXT_PRIVACY} target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href={PATH_EXT_TERMS} target='_blank' rel='noopener noreferrer'>
                  Terms & Conditions
                </a>
              </p>
            }
            error={{
              errorMessage: hasTriedToSubmit && formatEmailError(),
            }}
          />

          <ButtonPrimary
            isLoading={isLoading}
            className={styles.button}
            type='submit'
          >
            Continue
          </ButtonPrimary>
        </form>
      </div>

      <PawsightsContact vetName={vetName} />

      <PawsightsFooter />

      {isModalOpen ? (
        <VetOverlay onSuccess={handleVetOverlaySuccess} onSkip={handleVetSkip} />
      ) : null}
    </>
  );
}

export default PawsightsLanding;
